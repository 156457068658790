<template>
  <a-row class="content-fix" type="flex">
    <a-col class="height100 flex-1">
      <a-row class="btn-row">
        <button class="tab-btn" :class="{ active: tab == 'content' }" @click="tab = 'content'">
          <a-icon type="project" />{{ $t("ingradient") }}
        </button>

        <button class="tab-btn" :class="{ active: tab == 'photo' }" @click="tab = 'photo'">
          <a-icon type="picture" />{{ $t("Img") }}
        </button>
      </a-row>
      <div v-show="tab == 'content'" class="main-tabs" type="card">
        <!-- lang tabs -->
        <a-row align="middle" class="flex-baseline" style="margin-bottom: 15px">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            {{ $t("Lang") }}:
          </a-col>
          <a-col :span="12">
            <!-- lang translit tabs -->
            <a-row align="middle" class="flex-baseline flex-wrap">
              <lang-tabs v-model="langTab" class="mb-1x" />
            </a-row>
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="6" style="text-align: right; padding-right: 8px">Logo:
          </a-col>
          <a-col :span="12" class="sm-mb">
            <div class="form_image">
              <div class="org_image">
                <img :src="icon" />
              </div>
              <a-button style="font-size: 13px" type="primary" @click="uploadFile">
                <a-icon style="font-size: 16px" type="upload" /> Upload
                Image</a-button>
              <span v-if="!icon" style="padding-left: 8px">Картинка не выбрана</span>
              <input ref="fileInput" class="UploadhideInput" type="file"
                accept="image/jpg, image/jpeg, image/png, image/svg" @change="handleFile" />
            </div>
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            $t("TagsColTitle")
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input v-model="form.title[langTab]" placeholder="Введите название" />
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            Ссылка:
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input v-model="form.url" placeholder="Введите ссылку" />
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            Группа:
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input v-model="form.group" placeholder="Введите группа" />
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            Order:
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input v-model="form.order" type="number" placeholder="Order" />
          </a-col>
        </a-row>
        <a-row style="margin-top: 25px">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            Active:
          </a-col>
          <a-switch v-model="form.is_active" /> </a-row><br />
        <a-row align="middle" class="flex-baseline">
          <a-col :span="6" style="text-align: right; padding-right: 8px" />
          <a-col :span="12" class="sm-mb">
            <a-checkbox @change="checkChamber"> С контентом </a-checkbox>
          </a-col>
        </a-row>
        <a-row v-show="isContent" align="middle" style="margin-top: 25px" type="flex">
          <a-col :span="6" style="padding: 4px 8px 15px; text-align: right">
            {{ $t("Content") }}:
          </a-col>
          <a-col :span="23" style="margin: 0 auto">
            <!-- :key="langtab" -->
            <editor :key="getKey" :value="form.content[langTab]" :initial-value="form.content[langTab]" :init="{
              ...tinymce.default.props,
              menubar: 'file edit view insert format tools table tc help',
              setup: (editor) => {
                editor.ui.registry.addButton('spoiler-btn', {
                  icon: 'comment-add',
                  tooltip: 'Спойлер',
                  onAction: () => {
                    spoiler.modalVisibility = editor
                    spoilerHandler()
                  }
                })
                editor.ui.registry.addButton('storage-btn', {
                  icon: 'non-breaking',
                  tooltip:'Хранилище изображений',
                  onAction: () => {
                    openStoreModal()
                    editorInstance = editor
                  }
                })
              }
            }" @input="form.content[langTab] = editorChangeHandler(arguments[0])" />
          </a-col>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-col :span="6" />
          <a-col :span="12">
            <a-row>
              <a-button style="margin-right: 10px" @click="$router.go(-1)">
                <a-icon type="close" />{{ $t("Cancel") }}
              </a-button>
              <a-button type="primary" @click="submitData">
                <a-icon type="check" />Добавить
              </a-button>
            </a-row>
          </a-col>
        </a-row>
      </div>
      <!-- photo tab -->
      <div v-show="tab == 'photo'">
        <!-- upload photo -->
        <a-row align="middle">
          <a-col :span="6" style="padding-top: 4px; text-align: right; padding-right: 8px">
            {{ $t("TablePhotos") }}
          </a-col>
          <a-col :span="12">
            <image-upload :with-cropper="false" :buttons="true" :list="fileList" upload-multiple multiple
              accept="image/jpeg, image/png" @change="handleImages" @delete="deleteImage" />
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>

<script>
export default {
  components: {
    "image-upload": () => import("@/components/utils/image-upload"),
    editor: () => import("@tinymce/tinymce-vue")
  },
  data: () => {
    return {
      form: {
        title: {
          ru: "",
          uz: "",
          oz: "",
          en: ""
        },
        content: {
          ru: "",
          uz: "",
          oz: "",
          en: ""
        },
        url: "",
        group: "",
        type: 1,
        icon: "",
        images: [],
        order: "",
        is_active: true
      },
      fileList: [],
      isContent: false,
      langTab: "oz",
      image: null,
      tab: "content",
      icon: ""
    }
  },
  computed: {
    getKey() {
      return this.langTab
    }
  },
  methods: {
    uploadFile() {
      this.$refs.fileInput.click()
    },
    checkChamber(e) {
      this.isContent = e.target.checked
      console.log(this.isContent)
    },
    async submitData() {
      try {
        let form = this.form
        form = {
          ...form,
          title_ru: form.title.ru,
          title_uz: form.title.uz,
          title_oz: form.title.oz,
          title_en: form.title.en,
          content_ru: form.content.ru,
          content_uz: form.content.uz,
          content_oz: form.content.oz,
          content_en: form.content.en
        }
        if (this.isContent == false) {
          delete form.content_ru
          delete form.content_uz
          delete form.content_oz
          delete form.content_en
        }
        // delete form.content_qr
        // delete form.title
        // delete form.content
        let f = new FormData()
        Object.keys(form).forEach((k) => {
          form[k] && k != "images" && f.append(k, form[k])
          k == "is_active" && f.append(k, form[k])
        })
        form.images.forEach((k) => {
          f.append("images", k.fileTarget, k.fileTarget.name)
        })
        await this.$api.post(
          "/admin/common/interactive-service/create/?type=1",
          f
        )
        this.$message.success("Интерактивная услуга успешно создан")
        this.$router.push({ name: "interactiveServices" })
      } catch (e) {
        console.log(e)
      }
    },
    handleImages(arg) {
      this.form.images.push(arg[0])
      console.log(this.form.images, arg[0])
    },
    handleFile(e) {
      console.log(e.target.files[0])
      this.icon = URL.createObjectURL(e.target.files[0])
      this.form.icon = e.target.files[0]
    },
    async deleteImage(val) {
      if (this.form.id) {
        await this.$api.delete(
          "/admin/common/interactive-service/image/" + val + "/delete/"
        )
      } else {
        this.form.images = null
      }
    }
  }
}
</script>

<style>
.form_image>.org_image {
  width: 80px;
  height: 80px;
  border: 1px solid grey;
  border-radius: 50%;
  overflow: hidden;
  display: inline;
  margin-right: 20px;
}

.form_image>.org_image>img {
  width: 100%;
  height: 100%;
}

.form_image {
  margin: 10px 0;
  height: 70px;
  display: flex;
  align-items: center;
}
</style>
